(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
Function.prototype.debounce = function (wait, immediate) {
	var timeout,
	    func = this;
	return function () {
		var context = this,
		    args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

},{}],2:[function(require,module,exports){
module.exports = {
	dataObject: {},
	filters: [],
	debug: false,

	attach: function () {
		var self = this;
		$("[data-ga-trigger]").each(function () {
			var eventTrigger = $(this).data("ga-trigger");
			$(this).on(eventTrigger, $.proxy(self.trigger, self, $(this)));
		});
	},

	trigger: function ($element, triggerEvent) {
		this.dataObject = this.buildDataObject($element);
		this.dataObject = this.filterData($element, this.dataObject);
		this.cleanDataObject();
		this.sendDataObject(triggerEvent);
	},

	buildDataObject: function ($element) {
		return {
			'hitType': 'event',
			eventCategory: this.findData($element, "category"),
			eventAction: this.findData($element, "action"),
			eventLabel: this.findData($element, "label"),
			eventvalue: $element.data("ga-value")
		};
	},

	findData: function ($el, data) {
		return $el.data("ga-" + data) || $el.closest("[data-ga-" + data + "]").data("ga-" + data);
	},

	filterData: function ($element, data) {
		for (var i = this.filters.length - 1; i >= 0; i--) {
			var filterFunction = this.filters[i];
			if (typeof filterFunction === "function") {
				data = filterFunction.apply(filterFunction, [$element, data]);
			}
		}
		return data;
	},

	cleanDataObject: function () {
		for (var prop in this.dataObject) {
			if (typeof this.dataObject[prop] === "undefined") {
				delete this.dataObject[prop];
			}
		}
	},

	sendDataObject: function (triggerEvent) {
		if (this.debug) {
			console.log(this.dataObject);
			triggerEvent.preventDefault();
		} else {
			ga('send', this.dataObject);
		}
	},

	addDataFilter: function (filterFunc) {
		return this.filters.push(filterFunc);
	},

	enableDebugMode: function () {
		console.log("debug mode enabled");
		this.debug = true;
	}
};

},{}],3:[function(require,module,exports){
/* ======================================================================
Includes
===================================================================== */

var scrollClass = require("./scroll-class");
var FragmentRouter = require("./router");
var slider = require("./slider");
var PageSetter = require("./page-setter");
var gaEvents = require("./events");

/* ==========================================================================
	GA Events
	========================================================================= */

searchFilter = function ($element, data) {
	$search = $element.find('input[name="s"]');

	if ($search.length) {
		data["eventLabel"] = $search.val();
	}
	return data;
};

// manually fire successful Gravity Form submissions
$(document).on("gform_confirmation_loaded", function (event, form_id) {
	var $formConfirmation = $("#gform-confirmation-event-proxy-element-" + form_id);
	gaEvents.trigger($formConfirmation, event);
});

gaEvents.addDataFilter(searchFilter);

$(function () {
	gaEvents.attach();
});

/* ==========================================================================
	Modernizr
	========================================================================= */

Modernizr.addTest('ipad', function () {
	return !!navigator.userAgent.match(/iPad/i);
});

Modernizr.addTest('iphone', function () {
	return !!navigator.userAgent.match(/iPhone/i);
});

Modernizr.addTest('ipod', function () {
	return !!navigator.userAgent.match(/iPod/i);
});

Modernizr.addTest('appleios', function () {
	return Modernizr.ipad || Modernizr.ipod || Modernizr.iphone;
});

/* ======================================================================
	Routing
	===================================================================== */

if (!Modernizr.appleios) {
	$(document).on("click", ".fragment-link", function (event) {
		router = new FragmentRouter(event);
		return router.handleClick();
	});
}

/* ======================================================================
	Routing // Docs
	===================================================================== */

$(document).on("animation.pushload", "#fragment-loadpoint", function (event, $element, data) {
	var setter = new PageSetter(data);
	setter.reload();
});

/* ======================================================================
	Main Nav
	===================================================================== */

var maybeCloseNav = function (event) {
	var $clicked = $(event.target);
	if (!$clicked.closest('.primary-nav > .nav > .nav-item.open').length) {
		if ($("body").hasClass("open-nav")) {
			$(".primary-nav > .nav > .nav-item.open .slider-trigger").trigger("click");
		}
		$(document).off("click", maybeCloseNav);
	}
};

$(".primary-nav > .nav > .nav-item").on("toggleClass", function (event) {
	if (!$("body").hasClass("open-nav")) {
		$(document).off("click", maybeCloseNav);
		$(document).on("click", maybeCloseNav);
	}

	$("body").toggleClass("open-nav");
});

$(".primary-nav > .nav > .nav-item").on("removeClass", function (event) {
	$("body").removeClass("open-nav");
});

$(".primary-nav > .nav > .nav-item").on("addClass", function (event) {
	$("body").addClass("open-nav");
});

/* ======================================================================
	Mix Search Attachment
	===================================================================== */

var mixSearch = require("./mix-search");

$("form.mix-search").on("submit", function (event) {
	event.preventDefault();
	var mix = $(this).data("mix-group");
	var searcher = new mixSearch(this, mix);

	searcher.search();
	return false;
});

$("form.mix-search .input").on("keyup", function () {
	if (!this.value) {
		var group = $(this).closest("form.mix-search").data("mix-group");
		$(group).mixItUp("filter", "all");
	}
});

/* ==========================================================================
	Slider
	========================================================================= */

$(".slider-trigger").on("click", slider);
$(".slider-close").on("click", slider);
$(".slider-open").on("click", slider);

/* ======================================================================
	Utility
	===================================================================== */

var is_page = function (page) {
	return document.querySelector("body").classList.contains(page);
};

var isSmall = function () {
	return $(window).width() < 768;
};

/* ==========================================================================
	Search
	========================================================================= */

$(".search-trigger").on("click", function () {
	if ($(this).hasClass("is-open")) {
		$(this).removeClass("is-open");
	} else {
		$(this).addClass("is-open");
	}
});

/* ==========================================================================
	Mobile
	========================================================================= */

if (isSmall()) {
	$(".collapsing-nav-trigger").on("click", function (event) {
		event.preventDefault();
		$(this).closest(".collapsing-nav").toggleClass("open");
	});
}

/* ======================================================================
	Homepage Scroll Effects
	===================================================================== */

if ($("body").hasClass("home") && !isSmall()) {
	console.log("var");
	scrollClass(".primary-header", "large", function (scroll, position) {
		var bannerHeight = $(".frontpage-masthead").outerHeight();
		return scroll < bannerHeight * (1 / 3);
	});

	scrollClass("#scroll-btn", "active", function (scroll, position) {
		var bannerHeight = $(".frontpage-masthead").outerHeight();
		return scroll < bannerHeight * (2 / 3);
	});

	$("#scroll-btn").on("click", function (event) {
		event.preventDefault();
		$(".frontpage-feature-group").elScroll({
			// 100 = @header-height less variable
			offset: $(".primary-header").position().top + 100
		});
	});
}

/* ==========================================================================
	Mobile
	========================================================================= */

$(".collapsing-nav-trigger").on("click", function (event) {
	event.preventDefault();
	$(this).closest(".collapsing-nav").toggleClass("open");
});

/* ==========================================================================
	Posts // MixItUp Init
	========================================================================= */

$(function () {
	// Doesn't throw errors on pages without the mixitup script enqueued
	if ($("#filterable-list").length) {
		$("#filterable-list").mixItUp({
			animation: {
				effects: 'fade translateY'
			},
			load: {
				filter: $(".post-list-filters .filter.active").data("filter")
			},
			layout: {
				display: "block"
			},
			callbacks: {
				onMixStart: function (current, future) {
					$('[data-filter="' + current.activeFilter + '"]').removeClass("active");
					$('[data-filter="' + future.activeFilter + '"]').addClass("active");
				}
			}
		});
	}
});

/* ==========================================================================
	Posts // MixItUp Filter
	========================================================================= */

$(function () {
	if ($("#filterable-list").length) {
		$(".filter-link").on("click", function (event) {
			event.preventDefault();
		});
	}
});

/* ==========================================================================
	Footer Bar Sticky
	========================================================================= */

if (is_page("home") && !isSmall()) {
	scrollClass("#footer-bar", "fixed", function (scroll, position) {
		return scroll + window.innerHeight - this.offsetHeight < position;
	});
}

},{"./events":2,"./mix-search":4,"./page-setter":6,"./router":7,"./scroll-class":8,"./slider":9}],4:[function(require,module,exports){
/* ======================================================================
	Search
	===================================================================== */

var mixSearch = function (form, mix) {
	this.$form = $(form);
	this.$mix = $(mix);
};

mixSearch.prototype.search = function () {
	this.$form.addClass("loading");

	$.ajax({
		type: this.$form.attr('method'),
		url: this.$form.attr('action'),
		data: this.$form.serialize(),
		dataType: "json",
		success: $.proxy(this.onSuccess, this)
	});
};

mixSearch.prototype.onSuccess = function (data) {
	var ids = this.getIDs(data);

	if (!$.isArray(data) || !data.length) {
		ids = "none";
	}

	this.filter(ids);
	this.cleanUp();
};

mixSearch.prototype.getIDs = function (posts) {
	var IDs = [];
	$.each(posts, function (index, post) {
		IDs.push("post-" + post.ID);
	});
	return IDs;
};

mixSearch.prototype.filter = function (filter) {
	filter = this.sanitizeFilter(filter);
	$(this.$mix).mixItUp("filter", filter);
};

/* ======================================================================
	Sanitize Functions
	===================================================================== */

mixSearch.prototype.sanitizeFilter = function (filter) {
	if (filter === "all" && filter === "none") {
		return filter;
	}

	if ($.isArray(filter)) {
		filter = "." + filter.join(", .");
	} else if (typeof filter === "string" && !this.isSelector(filter)) {
		filter = "." + filter;
	}

	return filter;
};

mixSearch.prototype.isSelector = function (string) {
	return string.charAt(0) === "." || string.charAt(0) === "#";
};

/* ======================================================================
	Clean Up
	===================================================================== */

mixSearch.prototype.cleanUp = function () {
	this.$form.removeClass("loading");
};

/* ======================================================================
	Export
	===================================================================== */

module.exports = mixSearch;

},{}],5:[function(require,module,exports){
module.exports = function (element) {
	var distance = 0;
	if (element.offsetParent) {
		do {
			distance += element.offsetTop;
			element = element.offsetParent;
		} while (element);
	}
	return distance >= 0 ? distance : 0;
};

},{}],6:[function(require,module,exports){
PageSetter = function (data) {
	this.data = data;
};

PageSetter.prototype.reload = function () {
	this.setBodyClass();
	this.setPageTitle();
	this.activateNav();
};

PageSetter.prototype.setBodyClass = function () {
	if (typeof this.data.classes === "undefined") {
		return false;
	}

	var classes = this.data.classes,
	    classArray = [];

	if (typeof classes === "object" && !$.isArray(classes)) {
		for (var name in classes) {
			classArray.push(classes[name]);
		}
	} else if (typeof classes === "string") {
		classArray.push(classes);
	} else {
		classArray = classes;
	}

	$("body").attr("class", classArray.join(" "));
};

PageSetter.prototype.setPageTitle = function () {
	if (typeof this.data.title === "string") {
		window.history.pushState({}, this.data.title, this.data.url);
		document.title = this.decodeHtmlEntity(this.data.title);
	}
};

PageSetter.prototype.activateNav = function () {
	if (typeof this.data.url === "string") {
		$nav = $(".primary-nav");
		$nav.find(".dropdown-group .active").removeClass("active");
		$nav.find('[href="' + this.data.url + '"]').closest('li').addClass("active");
	}
};

PageSetter.prototype.decodeHtmlEntity = function (string) {
	var textArea = document.createElement('textarea');
	textArea.innerHTML = string;
	return textArea.value;
};

module.exports = PageSetter;

},{}],7:[function(require,module,exports){
var Router = function (event) {
	var transition = "bottom";
	var override = $(event.currentTarget).data("transition");

	this.event = event;
	this.transition = override ? override : transition;

	return false;
};

Router.prototype.handleClick = function () {
	if (this.isModifierClick(this.event)) {
		return true;
	}

	this.event.preventDefault();
	this.route();
	return false;
};

Router.prototype.isModifierClick = function (event) {
	return event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
};

Router.prototype.route = function () {
	var route = this.event.currentTarget.pathname;
	var fragmentURL = "/fragment" + this.event.currentTarget.pathname;

	this.load(fragmentURL);
};

Router.prototype.load = function (fragmentURL) {
	$("#fragment-loadpoint").pushLoad({
		transition: this.transition,
		ajax: {
			url: fragmentURL
		}
	});
};

module.exports = Router;

},{}],8:[function(require,module,exports){
var offsetTop = require("./offset-top");
require("./debounce");

module.exports = function (selector, css, condition, once, sequential) {

	// Set up our variables;
	var elements, checkers, checker;

	elements = document.querySelectorAll(selector);
	once = once ? once : false;
	checkers = [];

	// Our prototype scroll checker	
	checker = function (element, index, position, delay) {
		this.el = element;
		this.delay = delay || 0;

		// Handle any incoming events, in this case, only scroll
		this.handleEvent = function () {
			var self = this;
			var hasClass = this.el.classList.contains(css);

			// Check our conditional function
			if (condition.call(this.el, window.pageYOffset, position)) {

				// If our condition is true, add the class after specified delay
				setTimeout(function () {
					self.addClass.call(self, hasClass);
				}, this.delay);
			} else {
				this.removeClass(hasClass);
			}
		}.debounce(1);

		// Add the class and check if the listener should be removed
		this.addClass = function (hasClass) {
			!hasClass && this.el.classList.add(css);
			once && window.removeEventListener("scroll", checkers[index]);
		};

		// Remove the class if the condition is no longer true
		this.removeClass = function (hasClass) {
			hasClass && this.el.classList.remove(css);
		};
	};

	// Iterate over all the elements in the selector passed
	for (i = 0; i < elements.length; ++i) {
		var delay = !sequential ? 0 : i * 250;

		checkers[i] = new checker(elements[i], i, offsetTop(elements[i]), delay);
		window.addEventListener("scroll", checkers[i], false);
		checkers[i].handleEvent();
	}
};

},{"./debounce":1,"./offset-top":5}],9:[function(require,module,exports){
module.exports = function (event) {

	var master = this;
	var runner = {};
	var action, $cntnr, $collection, $open;

	/* ==========================================================================
 	Get Some Vars
 	========================================================================= */

	// .is() over .hasClass() for SVG support :(
	action = $(this).is(".slider-trigger") ? "toggleClass" : false;
	action = !action && $(this).is(".slider-close") ? "removeClass" : action;
	action = !action && $(this).is(".slider-open") ? "addClass" : action;

	$cntnr = $(this).data("slider-group") ? $($(this).data("slider-group")) : $(this).closest(".slider-group");
	$collection = $cntnr.data("slider-collection") ? $($cntnr.data("slider-collection")) : false;

	/* ==========================================================================
 	Transition Detection
 	========================================================================= */

	function whichTransitionEvent() {
		var t,
		    el = document.createElement("fakeelement");

		var transitions = {
			"transition": "transitionend",
			"OTransition": "oTransitionEnd",
			"MozTransition": "transitionend",
			"WebkitTransition": "webkitTransitionEnd"
		};

		for (t in transitions) {
			if (el.style[t] !== undefined) {
				return transitions[t];
			}
		}
	}

	var transitionEvent = whichTransitionEvent();

	/* ==========================================================================
 	Runner
 	========================================================================= */

	runner.run = function ($cntnr, action) {

		var self = this;

		/* ==========================================================================
  	Var Setup
  	========================================================================= */

		this.action = action;
		this.$cntnr = $cntnr;
		this.type = this.$cntnr.data("slide");
		this.$target = this.$cntnr.find(".slider-target").filter(":first");

		/* ==========================================================================
  	Make a Clone for Size Comparisons
  	========================================================================= */

		this.makeClone = function () {
			self.$clone = self.$cntnr.clone();
			self.$clone.css({
				display: 'block',
				position: 'fixed',
				visibility: 'hidden'
			});

			self.$clone.appendTo(self.$cntnr.parent());
			self.$cloneTarget = self.$clone.find(".slider-target");
		};

		/* ==========================================================================
  	Animation Types
  	========================================================================= */

		this.vertical = function () {
			// First explicitly set our current height
			self.$target.height(self.$cloneTarget.outerHeight());

			// Then toggle the class, and set our new height
			self.$clone[self.action]("open");
			var height = self.$cloneTarget.outerHeight();
			self.$target.height(height);
		};

		this.horizontal = function () {
			// First explicitly set our current width
			self.$target.width(self.$cloneTarget.outerWidth());

			// Then toggle the class, and set our new width
			self.$clone[self.action]("open");
			var width = self.$cloneTarget.outerWidth();
			self.$target.width(width);
		};

		/* ==========================================================================
  	Callable Funcs
  	========================================================================= */

		this.toggle = function () {
			self.$cntnr.toggleClass("open");
		};

		this.open = function () {
			self.$cntnr.remove("open");
		};

		this.close = function () {
			self.$cntnr.remove("open");
		};

		/* ==========================================================================
  	Utility
  	========================================================================= */

		this.cleanup = function () {
			self.$clone.remove();
		};

		/* ==========================================================================
  	Do the thing.
  	========================================================================= */

		event.preventDefault();
		event.stopPropagation();

		self.$target.one(transitionEvent, function (event) {
			$(this).removeClass("animating").removeAttr("style");
		});

		this.makeClone();
		this.$target.addClass("animating");
		this[this.type].call();

		this.$cntnr[self.action]("open");
		this.$cntnr.trigger(self.action, [this.$target, this.$trigger]);

		this.cleanup();
	};

	/* ==========================================================================
 	Do the Things
 	========================================================================= */

	if ($collection) {
		$open = $collection.find(".open");
		$open.length && !$open.is($cntnr) && runner.run($open, "removeClass");
	}
	runner.run($cntnr, action);
};

},{}]},{},[3])